

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlType } from '@common-src/model/form-control';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import LabelService from '@common-src/service/label';

@Component
export default class FacilityBatchDeleteLabelDialog extends FormDialogComponent<any> {
    public ids: Array<string>;
    public type: String;

    dialogOpen(data: any, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.ids = data.selectedRowIds;
        this.type = data['tagEntityType'];
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.dialogTitle = '批量删除标签';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItem = {
                    key: 'tagIds',
                    value: _.map(data.tagIds, item => item.value),
                    label: '标签',
                    type: FormControlType.SELECT,
                    options: data.tagIds,
                    mode: 'multiple',
                    required: true
                };
                this.jtlForm.initFormByControls([controlItem]);
            }
        });
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            const params = {
                entityIds: this.ids,
                tagIds: this.jtlForm.formModel.tagIds,
                relType: this.type
            };
            LabelService.batchDeleteEntityTagRelations(params).then(res => {
                this.showMessageSuccess('批量删除标签成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }
}
